#main,#root,body,html {
  height: 100%;
  color: white;
  scroll-behavior: smooth;

  
}

#aboutLink::before, #experienceLink::before, #portfolioLink::before { 
  display: block; 
  content: " "; 
  margin-top: -80px; 
  height: 80px; 
  visibility: hidden; 
  pointer-events: none;
}
a, a:hover {
  color: white;
  text-decoration: none;
}

svg {
  margin-top: -1px;
  margin-bottom: -1px;
}

#root {
  background-color: #0c1445;
}
.navbar {
  width: 100%;
  background-color: #0c1445;
  height: 10%;
  z-index: 999999;
  position: fixed;
  top: 0;
}

.scroll-btn {
  text-align: center;
  color: white;
  font-size: 20px;
  bottom: 0%;
  margin-top: 0%;
}

.wrapper {
  height: 90%;
  display: grid;
  place-items: center;
}

.wrapper img {
  width: 350px;
  height: 350px;
}

.container {
  height: 100%;
  text-align: center;
}

.nav-link {
  color: white;
  font-size: 20px;
}

.nav-link:hover {
  color: white;
  text-decoration: underline;
}

.nameType h1 {
  overflow: hidden;
  border-right: .15em solid rgb(198, 220, 255);
  text-align: center;
  white-space: nowrap; 
  margin: 0 auto; 
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  10% { border-color: rgb(198, 220, 255); }
}

.fullPage-white {
  height: 30%;
  background-color: white;
  color: black;
}

.fullPage-blue {
  text-align: center;
  margin-top: -6px;
  height: 100%;
  background-color: #0c1445;
  color: white;
}

#timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

#timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;

}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  right: -17px;
  background-color: #0c1445;
  border: 2px solid white;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}


.timeline-left {
  left: 0;
}

.timeline-right {
  left: 50%;
}
.timeline-right::after {
  left: -16px;
  margin-bottom: 10%;
}

.container-content {
  padding: 20px 30px;
  background-color: #00274d;
  color: white;
  position: relative;
  border-radius: 50px;
  border-color: white;
  border: 2px solid white;
}

#experienceLink {
  height: 1000px;
}

@media screen and (max-width: 700px) {
  #experienceLink {
    height: 1300px;
  }
  .nav-link {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 400px) {
  #experienceLink {
    height: 1850px;
  }
  .nav-link {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 800px) {
  .card {
    width: 100% !important;
    display: block !important;
  }
}
@media screen and (max-width: 1500px) {
  #timeline::after {
  left: 31px;
  }
  #aboutLink {
    height: 70%;
  }
  
  .timeline-container {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }
  
  .timeline-container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  }

  .timeline-left::after, .timeline-right::after {
  left: 15px;
  }

  .timeline-right {
  left: 0%;
  }
}

.frontEndimg {
  width: 150px;
  height: 150px;
  margin-right: 5%;
  margin-top: 1%;
}

.technologies {
  margin-top: 5%;
}
